import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../service/authentication.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {Observable} from 'rxjs/index';
import {map} from 'rxjs/operators';
import {filter} from 'rxjs/operators';

@Injectable()
export class OfferMaintenanceGuard {

  constructor(private store: Store<fromRoot.AppState>, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(fromRoot.getUserState).pipe(
      // filter out userState objects where userInfo is not yet set
      filter(userState => !!userState.userInfo),
      map(userState => {
        if (AuthenticationService.canSellOffersForAnyChannel(userState)
          || userState.isCustodianPb
          || userState.isClerkPb) {
          return true;
        } else {
          if (AuthenticationService.canBuyOffers('PIA', userState)
            || AuthenticationService.canBuyOffers('ALL_UC', userState)
            || AuthenticationService.canBuyOffers('DIN_BIL', userState)
            || AuthenticationService.canBuyOffers('PB', userState)
            || AuthenticationService.canBuyOffers('VGRX', userState)) {
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/logout']);
          }
          return false;
        }
      }));
  }
}
